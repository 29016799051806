<template>
  <v-container fluid style="height: 100vh">
    <headerToolbar>
      <template v-slot:toolbarTitle>
        <b class="ml-3">GetCollective Translator UI</b>
      </template>

      <template slot="toolbarRight">
        <v-spacer></v-spacer>

        <v-btn
          @click="exportLanguage(index)"
          v-for="(e, index) in supported_languages"
          :key="e"
          class="mr-2"
          small
          dark
          depressed
          color="button_blue"
        >
          <flag :iso="supported_languages_ico[index]" /><span class="pl-2">
            Export {{ e }}</span
          ></v-btn
        >
      </template>
    </headerToolbar>

    <v-row
      wrap
      no-gutters
      style="max-width: 1280px; height: calc(100vh - 98px); overflow-y: auto"
    >
      <v-col cols="12" class="mb-6">
        <v-expansion-panels >
          <span style="display: none">{{refreshIndex}}</span>
          <v-expansion-panel
            v-for="(group, groupIndex) in Object.keys(english_translation)"
            :key="group"
          >
            <v-expansion-panel-header class="justify-end">
              <h5>{{ uncamelize(group) }}</h5>

              <div style="display: contents" class="caption">
                <template v-if="countTranslationsInGroup(group)">{{
                  countTranslationsInGroup(group)
                }}</template>
                <template v-else>
                  <span
                    style="display: contents; left: -12px"
                    class="success--text"
                    ><v-icon small class="pr-2" color="success"
                      >mdi-check-bold</v-icon
                    >
                    Fully translated</span
                  ></template
                >
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Loop trought every keys of the group -->
              <template v-for="(key, keyIndex) in english_translation[group]">
                <div :key="'w' + groupIndex + '_' + keyIndex">
                  <!-- IF IT'S AN NESTED OBJECT -->
                  <div class="ml-8 pt-4 pb-2" v-if="isObject(key)">
                    <h5 dense class="font-weight-bold pl-0">
                      {{ uncamelize(group) + " > " + uncamelize(keyIndex) }}
                    </h5>
                    <template
                      v-for="(subKey, subKeyIndex) in english_translation[
                        group
                      ][keyIndex]"
                    >
                      <div
                        :key="
                          'w' + groupIndex + '_' + keyIndex + '_' + subKeyIndex
                        "
                      >
                        <template>
                          <v-row wrap no-gutters class="hoverRow">
                            <v-col
                              v-for="(
                                language, index
                              ) in supported_languages_data"
                              :key="
                                supported_languages[index] +
                                keyIndex +
                                subKeyIndex
                              "
                              class="px-4"
                            >
                              <v-textarea
                                dense
                                :value="language[group][keyIndex][subKeyIndex]"
                                flat
                                :placeholder="
                                  index == 0
                                    ? base_en_translation[group][keyIndex][
                                        subKeyIndex
                                      ]
                                    : 'Missing translation'
                                "
                                auto-grow
                                rows="1"
                                hide-details
                                background-color="transparent"
                                @change="
                                  (v) => {
                                    language[group][keyIndex][subKeyIndex] = v;
                                    refreshIndex++;
                                  }
                                "
                                style="font-size: 14px"
                              >
                                <template slot="prepend-inner">
                                  <div
                                    class="pr-2 pt-2"
                                    style="font-size: 12px"
                                  >
                                    <flag
                                      :iso="supported_languages_ico[index]"
                                    />
                                  </div>
                                </template>
                              </v-textarea>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                    </template>
                  </div>

                  <!-- IF IT'S A STRING -->
                  <template v-else>
                    <v-row wrap no-gutters class="hoverRow">
                      <v-col
                        v-for="(language, index) in supported_languages_data"
                        :key="supported_languages[index] + group + index"
                        class="px-4"
                      >
                        <v-textarea
                          :id="
                            supported_languages[index] +
                            group +
                            index +
                            keyIndex
                          "
                          dense
                          :value="language[group][keyIndex]"
                          flat
                          auto-grow
                          rows="1"
                          :placeholder="
                            index == 0
                              ? base_en_translation[group][keyIndex]
                              : 'Missing translation'
                          "
                          hide-details
                          background-color="transparent"
                          @change="
                            (v) => {
                              language[group][keyIndex] = v;
                              refreshIndex++;
                            }
                          "
                          style="font-size: 14px"
                        >
                          <template slot="prepend-inner">
                            <div class="pr-2 pt-2" style="font-size: 12px">
                              <flag :iso="supported_languages_ico[index]" />
                            </div>
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <!-- <v-row
        wrap
        no-gutters
      >
        <v-col cols="12" class="mb-6">
          <v-btn
            @click="openFile(index)"
            v-for="(e, index) in supported_languages"
            :key="e"
            class="mr-2"
            small
            dark
            depressed
            color="button_blue"
          >
            <flag :iso="supported_languages_ico[index]" />
            <span class="pl-2">
              Import {{ e }}
            </span>
            <input
              @change="importLanguage(index)"
              :id="'id_' + supported_languages_ico[index]"
              type="file"
              style="display:none;"
            />
          </v-btn>
        </v-col>
      </v-row> -->
    </v-row>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import base_en_translation from "@/locales/en.js";
import base_fr_translation from "@/locales/fr.js";

export default {
  name: "translator_ui",
  mixins: [],
  components: {
    headerToolbar,
  },
  data() {
    return {
      refreshIndex: 0,
      base_en_translation,
      base_fr_translation,
      english_translation: null,
      french_translation: null,
      imported_translations: {},
      supported_languages: ["English", "French"],
      supported_languages_ico: ["gb", "fr"],
      supported_languages_filename: ["en.js", "fr.js"],
      supported_languages_data: [],
    };
  },
  computed: {},
  methods: {
    openFile(index) {
      document.getElementById('id_' + this.supported_languages_ico[index]).click();
    },
    countTranslationsInGroup(group) {
      // var total_translations_strings = 0;
      var total_missing_translation = 0;
      var EN = this.english_translation;

      for (var i in EN[group]) {
        if (this.isObject(EN[group][i])) {
        } else {
          this.supported_languages_data
            .map((sld) => sld[group][i])
            .forEach((item, index) => {
              item ? "" : total_missing_translation++;
            });
        }
      }

      return total_missing_translation > 0
        ? "Missing " + total_missing_translation + " translation(s)"
        : "";
      // return total_missing_translation + "/" + total_translations_strings;
    },
    uncamelize(string) {
      var result = string.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    isObject(value) {
      return !!value && value.constructor === Object;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = x=> resolve(fr.result);
        fr.readAsText(file);
      })
    },
    async importLanguage(index) {
      let input = document.getElementById('id_' + this.supported_languages_ico[index]);
      let language = this.supported_languages[index];
      let content = await this.readFile(input.files[0]);
      //let evaluated_content = eval(content);

      console.log('importing ', language);
      console.log('imported content ', content);
      //console.log('evaluated content ', evaluated_content);
    },
    exportLanguage(index) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(
            "export default" +
              JSON.stringify(this.supported_languages_data[index], null, "\t").replaceAll('""', null)
          )
      );
      element.setAttribute(
        "download",
        this.supported_languages_filename[index]
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    fillTranslatedWords() {
      var EN = this.english_translation;
      this.supported_languages_data.forEach((LANG, LANG_INDEX) => {
        Object.keys(EN).forEach((group) => {
          if (!(group in LANG)) LANG[group] = {};

          Object.keys(EN[group]).forEach((item) => {
            if (!(item in LANG[group])) {
              LANG[group][item] = this.isObject(EN[group][item]) ? {} : "";
            }

            if (this.isObject(EN[group][item])) {
              Object.keys(EN[group][item]).forEach((subItem) => {
                if (!(subItem in LANG[group][item]))
                  LANG[group][item][subItem] = "";
              });
            }
          });
        });
        console.log(this.supported_languages[LANG_INDEX], LANG);
      });
    },
  },
  mounted() {},
  created() {
    this.english_translation = JSON.parse(JSON.stringify(base_en_translation));
    this.french_translation = JSON.parse(JSON.stringify(base_fr_translation));

    this.supported_languages_data = [
      this.english_translation,
      this.french_translation,
    ];
    this.fillTranslatedWords();
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: var(--v-light_grey) !important;
}

.hoverRow:hover {
  background-color: var(--v-light_grey);
}
</style>



